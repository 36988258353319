import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import {SxProps} from "@mui/material";

type IAutoCompleteSelect = {
	label: string
	onChange: (newValues: string[]) => void
	options: IOption[]
	placeholder?: string
	fontSize?: '5px'
	value: string[]
	disabled?: boolean
	customSx?: SxProps
	tagsLimit?: number
	error?: boolean
	required?: boolean
	helperText?: string
	isMultiSelect?: boolean
}

type IOption = {
	title: string
	id: string
}

const AutoCompleteSelect = ({
															options,
															onChange,
															label,
															fontSize,
															value,
															disabled,
															customSx,
															tagsLimit = 1,
															error,
															required,
															helperText,
															isMultiSelect = true
														}: IAutoCompleteSelect) => {

	const handleChange = (_: any, newValue: IOption | IOption[] | null) => {
		if (isMultiSelect) {
			const selectedValues = (newValue as IOption[] || []).map(({id}) => id);
			onChange(selectedValues);
		} else {
			const selectedValue = newValue ? [(newValue as IOption).id] : [];
			onChange(selectedValue);
		}
	}

	const selectedValue = isMultiSelect
		? options.filter(option => value.includes(option.id))
		: options.find(option => value[0] === option.id) || null;

	return (
		<Autocomplete
			sx={{width: '100%', fontSize: `${fontSize}`, ...customSx}}
			multiple={isMultiSelect}
			limitTags={tagsLimit}
			onChange={handleChange}
			id="auto-complete-select"
			size={'small'}
			options={options}
			value={selectedValue}
			getOptionLabel={(option) => option?.title}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					sx={{fontSize: `${fontSize} !important`}}
					error={error}
					required={required}
					helperText={helperText}
				/>
			)}
			disabled={disabled}
			blurOnSelect={!isMultiSelect}
		/>
	)
}

export default AutoCompleteSelect;
