import React, {useCallback, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, TextField} from '@mui/material';
import {useAppDispatch} from '../../../redux/storeExports';
import {addingNewBrokerRecord, getParentsBrokerList} from '../../../redux/brokerService';
import AutoCompleteSelect from '../atom/AutoCompleteSelect';
import {BROKER_EXECUTION_STATUSES, BROKER_STATUSES} from "@meprism/shared/src/utils/constants";
import {z} from 'zod';

// Zod Schema
const formSchema = z.object({
	muid: z.string().min(1, 'MUID is required'),
	brokerNames: z.array(z.string()).min(1, 'At least one broker must be selected'),
	executionStatuses: z.array(z.string()).min(1, 'At least one execution status must be selected'),
	statuses: z.array(z.string()).min(1, 'At least one status must be selected'),
	profileUrl: z.string().optional(),
	firstName: z.string().min(1, 'First name is required'),
	middleName: z.string().optional(),
	lastName: z.string().min(1, 'Last name is required'),
	country: z.string().min(1, 'Country is required'),
	city: z.string().min(1, 'City is required'),
	state: z.string().min(1, 'Age is required'),
	age: z.string().optional(),
});

// Types
type FormState = z.infer<typeof formSchema>;
type FormErrors = Partial<Record<keyof FormState, string>>;

interface BrokerOption {
	title: string;
	id: string;
}

// Constants
const INITIAL_FORM_STATE: FormState = {
	muid: '',
	brokerNames: [],
	executionStatuses: [],
	statuses: [],
	profileUrl: '',
	firstName: '',
	middleName: '',
	lastName: '',
	country: '',
	city: '',
	state: '',
	age: '',
};

const INITIAL_ERROR_STATE: FormErrors = {};

const ALL_BROKER_OPTION: BrokerOption = {
	title: 'All',
	id: 'all',
};

const AddBrokerRecordPopup: React.FC = () => {
	const dispatch = useAppDispatch();

	// State
	const [dataBrokerOptions, setDataBrokerOptions] = useState<BrokerOption[]>([]);
	const [formState, setFormState] = useState<FormState>(INITIAL_FORM_STATE);
	const [formErrors, setFormErrors] = useState<FormErrors>(INITIAL_ERROR_STATE);
	const [dialogOpen, setDialogOpen] = useState(false);

	// Validation
	const validateForm = useCallback((): boolean => {
		const result = formSchema.safeParse(formState);

		if (!result.success) {
			const newErrors: FormErrors = {};
			result.error.issues.forEach((issue) => {
				const path = issue.path[0] as keyof FormState;
				newErrors[path] = issue.message;
			});
			setFormErrors(newErrors);
			return false;
		}

		setFormErrors({});
		return true;
	}, [formState]);

	// Fetch Brokers List
	const fetchParentsBrokerList = useCallback(async () => {
		try {
			const response = await dispatch(getParentsBrokerList());
			const brokers: BrokerOption[] = response.payload.brokers.map((broker: string) => ({
				title: broker,
				id: broker,
			}));
			setDataBrokerOptions([ALL_BROKER_OPTION, ...brokers]);
		} catch (error) {
			console.error('Failed to fetch active brokers:', error);
		}
	}, [dispatch]);

	useEffect(() => {
		dialogOpen && fetchParentsBrokerList();
	}, [dialogOpen, fetchParentsBrokerList]);

	// Form handlers
	const handleFormChange = useCallback(<T extends keyof FormState>(
		field: T,
		value: FormState[T]
	) => {
		setFormState(prev => ({...prev, [field]: value}));
		// Validate the field immediately
		const fieldSchema = formSchema.shape[field];
		const result = fieldSchema.safeParse(value);

		setFormErrors(prev => ({
			...prev,
			[field]: !result.success ? result.error.issues[0].message : undefined
		}));
	}, []);

	const handleBrokerChange = useCallback((values: string[]) => {
		const selectedBrokers = values.includes(ALL_BROKER_OPTION.id)
			? dataBrokerOptions
				.filter(({id}) => id !== ALL_BROKER_OPTION.id)
				.map(({id}) => id)
			: values;
		handleFormChange('brokerNames', selectedBrokers);
	}, [dataBrokerOptions, handleFormChange]);

	// Dialog handlers
	const toggleDialog = useCallback((open: boolean) => {
		setDialogOpen(open);
		if (!open) {
			setFormState(INITIAL_FORM_STATE);
			setFormErrors(INITIAL_ERROR_STATE);
		}
	}, []);

	const handleRetriggerScans = useCallback(async () => {
		if (!validateForm()) return;
		try {
			await dispatch(addingNewBrokerRecord(
				{
					brokerNames: formState.brokerNames,
					executionStatuses: formState.executionStatuses,
					statuses: formState.statuses,
					userDetails: {
						muid: formState.muid,
						first_name: formState.firstName,
						last_name: formState.lastName,
						middle_name: formState.middleName,
						country: formState.country,
						city: formState.city,
						state: formState.state,
						age: formState.age,
						profile_url: formState.profileUrl
					}
				}
			)).unwrap();
			toggleDialog(false);
		} catch (error) {
			console.error('Failed to retrigger scans:', error);
		}
	}, [validateForm, dispatch, toggleDialog, formState]);

	return (
		<div>
			<Button
				variant="outlined"
				color="success"
				onClick={() => toggleDialog(true)}
				sx={{minWidth: '150px'}}
			>
				Add New Record
			</Button>

			<Dialog
				open={dialogOpen}
				onClose={() => toggleDialog(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent>
					<DialogContentText sx={{mb: 7, textAlign: 'center'}}>
						Please fill out the fields below to add a new record.
					</DialogContentText>
					<Grid container spacing={4} justifyContent="center" alignItems="center">
						<Grid item lg={6} md={6} xs={12}>
							<TextField
								value={formState.muid}
								onChange={e => handleFormChange('muid', e.target.value.trim())}
								label="MUID"
								size="small"
								fullWidth
								error={!!formErrors.muid}
								helperText={formErrors.muid}
								required
							/>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<AutoCompleteSelect
								options={dataBrokerOptions}
								onChange={handleBrokerChange}
								label="Data Brokers"
								value={formState.brokerNames}
								tagsLimit={3}
								error={!!formErrors.brokerNames}
								helperText={formErrors.brokerNames}
								required
							/>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<AutoCompleteSelect
								options={BROKER_EXECUTION_STATUSES}
								onChange={values => handleFormChange('executionStatuses', values)}
								label="Execution Status"
								value={formState.executionStatuses}
								tagsLimit={3}
								error={!!formErrors.executionStatuses}
								helperText={formErrors.executionStatuses}
								required
								isMultiSelect={false}
							/>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<AutoCompleteSelect
								options={BROKER_STATUSES}
								onChange={values => handleFormChange('statuses', values)}
								label="Status"
								value={formState.statuses}
								tagsLimit={3}
								error={!!formErrors.statuses}
								helperText={formErrors.statuses}
								required
								isMultiSelect={false}
							/>
						</Grid>
						<Grid item lg={4} md={6} xs={12}>
							<TextField
								value={formState.firstName}
								onChange={e => handleFormChange('firstName', e.target.value.trim())}
								label="First Name"
								size="small"
								fullWidth
								error={!!formErrors.firstName}
								helperText={formErrors.firstName}
								required
							/>
						</Grid>
						<Grid item lg={4} md={6} xs={12}>
							<TextField
								value={formState.middleName}
								onChange={e => handleFormChange('middleName', e.target.value.trim())}
								label="Middle Name"
								size="small"
								fullWidth
							/>
						</Grid>
						<Grid item lg={4} md={6} xs={12}>
							<TextField
								value={formState.lastName}
								onChange={e => handleFormChange('lastName', e.target.value.trim())}
								label="Last Name"
								size="small"
								fullWidth
								error={!!formErrors.lastName}
								helperText={formErrors.lastName}
								required
							/>
						</Grid>
						<Grid item lg={4} md={6} xs={12}>
							<TextField
								value={formState.country}
								onChange={e => handleFormChange('country', e.target.value)}
								label="Country"
								size="small"
								fullWidth
								error={!!formErrors.country}
								helperText={formErrors.country}
								required
							/>
						</Grid>
						<Grid item lg={4} md={4} xs={12}>
							<TextField
								value={formState.city}
								onChange={e => handleFormChange('city', e.target.value)}
								label="City"
								size="small"
								fullWidth
								error={!!formErrors.city}
								helperText={formErrors.city}
								required
							/>
						</Grid>
						<Grid item lg={2} md={4} xs={12}>
							<TextField
								value={formState.state}
								onChange={e => handleFormChange('state', e.target.value.trim())}
								label="State"
								size="small"
								fullWidth
								error={!!formErrors.state}
								helperText={formErrors.state}
								required
							/>
						</Grid>
						<Grid item lg={2} md={4} xs={12}>
							<TextField
								value={formState.age}
								onChange={e => handleFormChange('age', e.target.value.trim())}
								label="Age"
								size="small"
								fullWidth
							/>
						</Grid>
						<Grid item lg={12} md={12} xs={12}>
							<TextField
								value={formState.profileUrl}
								onChange={e => handleFormChange('profileUrl', e.target.value.trim())}
								label="Profile URL"
								size="small"
								fullWidth
								error={!!formErrors.profileUrl}
								helperText={formErrors.profileUrl}
							/>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => toggleDialog(false)} color="error">
						Cancel
					</Button>
					<Button
						onClick={handleRetriggerScans}
						color="primary"
					>
						Add Record
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddBrokerRecordPopup;
