import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, TextField} from '@mui/material';
import {useAppDispatch} from '../../../redux/storeExports';
import {getParentsBrokerList, retriggerScans} from '../../../redux/brokerService';
import AutoCompleteSelect from '../atom/AutoCompleteSelect';
import {BrokerOption} from "./FilterContainer";

// Types
interface FormState {
	muid: string;
	brokerNames: string[];
}

const INITIAL_FORM_STATE: FormState = {
	muid: '',
	brokerNames: [],
};

const ALL_BROKER_OPTION: BrokerOption = {
	title: 'All',
	id: 'all',
};

const RetriggerScansPopup: React.FC = () => {
	const dispatch = useAppDispatch();

	// State
	const [dataBrokerOptions, setDataBrokerOptions] = useState<BrokerOption[]>([]);
	const [formState, setFormState] = useState<FormState>(INITIAL_FORM_STATE);
	const [dialogOpen, setDialogOpen] = useState(false);

	// Fetch Brokers List
	const fetchParentsBrokerList = useCallback(async () => {
		try {
			const response = await dispatch(getParentsBrokerList());
			const brokers: BrokerOption[] = response.payload.brokers.map((broker: string) => ({
				title: broker,
				id: broker,
			}));
			setDataBrokerOptions([ALL_BROKER_OPTION, ...brokers]);
		} catch (error) {
			console.error('Failed to fetch parent brokers:', error);
		}
	}, [dispatch]);

	useEffect(() => {
		if (dialogOpen) {
			fetchParentsBrokerList();
		}
	}, [dialogOpen, fetchParentsBrokerList]);

	// Form handlers
	const resetForm = useCallback(() => {
		setFormState(INITIAL_FORM_STATE);
	}, []);

	const handleMuidChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.trim();
		setFormState((prev) => ({...prev, muid: value}));
	}, []);

	const handleBrokerChange = useCallback((values: string[]) => {
		setFormState((prev) => ({
			...prev,
			brokerNames: values.includes(ALL_BROKER_OPTION.id)
				? dataBrokerOptions
					.filter(({id}) => id !== ALL_BROKER_OPTION.id)
					.map(({id}) => id)
				: values,
		}));
	}, [dataBrokerOptions]);

	// Dialog handlers
	const handleDialogOpen = useCallback(() => {
		setDialogOpen(true);
	}, []);

	const handleDialogClose = useCallback(() => {
		resetForm();
		setDialogOpen(false);
	}, [resetForm]);

	const handleRetriggerScans = useCallback(async () => {
		const {muid, brokerNames} = formState;

		if (!muid || brokerNames.length === 0) return;

		try {
			await dispatch(retriggerScans({
				muid,
				provider_names: brokerNames
			})).unwrap();
			handleDialogClose();
		} catch (error) {
			console.error('Failed to retrigger scans:', error);
		}
	}, [dispatch, formState, handleDialogClose]);

	// Memoized values
	const isSubmitDisabled = useMemo(() =>
			!formState.muid || formState.brokerNames.length === 0,
		[formState.muid, formState.brokerNames.length]
	);

	return (
		<div>
			<Button
				variant="contained"
				color="success"
				onClick={handleDialogOpen}
				sx={{minWidth: '150px'}}
			>
				Retrigger Scans
			</Button>

			<Dialog
				open={dialogOpen}
				onClose={handleDialogClose}
				maxWidth="sm"
				fullWidth
			>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to retrigger scans for the selected records?
					</DialogContentText>

					<TextField
						value={formState.muid}
						onChange={handleMuidChange}
						label="MUID"
						size="small"
						fullWidth
						sx={{my: 3}}
					/>

					<AutoCompleteSelect
						options={dataBrokerOptions}
						onChange={handleBrokerChange}
						label="Data Brokers"
						value={formState.brokerNames}
					/>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={handleRetriggerScans}
						color="primary"
						disabled={isSubmitDisabled}
					>
						Retrigger Scan
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RetriggerScansPopup;
